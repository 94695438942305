@import "../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html, body {
  font-family: "Roboto", system-ui;
  font-weight: 400;
  font-style: normal;
}

$topbar-height:40px;
$accent-color: #94C9A9;

.loadingContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: $accent-color;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15{ 
  100%{transform: rotate(1turn)}
}

body{
 background-color: rgb(250, 250, 250);
 height: 100vh;
}


.table-meggi {
  --bs-table-color: #000;
  --bs-table-bg: #F2CEEF;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #F2CEEF;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
  cursor: pointer;
}

.table-robin {
  --bs-table-color: #000;
  --bs-table-bg: #C0E6F5;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #C0E6F5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
  cursor: pointer;
}

.table-zusammen {
  --bs-table-color: #000;
  --bs-table-bg: #DAF2D0;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #DAF2D0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
  cursor: pointer;
}

.item-meggi {
  background-color: #F2CEEF;
}

.item-robin {
  background-color: #C0E6F5;
}

.item-zusammen {
  background-color: #DAF2D0;
}

.item-color-meggi {
  color: #F2CEEF;
}

.item-color-robin {
  color: #C0E6F5;
}

.item-color-zusammen {
  color: #DAF2D0;
}



.nlink {
  i {
    color: gray;
    font-size: 2rem;
  }
}

.active-link {
  color: black;
  
  font-size: 2.4rem;
}




.TableItemContainer {
  padding-top: $topbar-height;
  height: 100vh;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tableItem {
  margin-bottom: .5rem;
  border-radius: .5rem;
  border: 1px solid lightgray;
  .row {
    cursor: pointer;
  }
}





.tableItem:last-child{
  margin-bottom: 100px;
}

.TableItemMonthInfo {
  margin-bottom: .5rem;
  border-radius: .5rem;
  font-weight: bold;
}


.TableStatsContainer {
  display: flex;
  justify-content: center;
  padding-top: $topbar-height;
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid lightgray;
  padding: .5rem;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.TableItemContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.TableItemContainer{
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.custom-nav{
  background-color: #fff;
  border-bottom: 1px solid lightgray;
  height: $topbar-height;
}

.custom-bottom-nav {
  background-color: #fff;
  border-top: 1px solid lightgray;
  height: 100px;
  .row {
    width: 390px;
  }
}

//! Add

.inputContainer {
  display: flex;
  flex-direction: column;
}

//! Custome Radios 

.radioSelection {
  display: flex;
  justify-content: space-between;
}


.radioItemContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  .radioItem{
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  label {
    position: relative;
    font-size: 20px;
    border: 2px solid $accent-color;
    border-radius: 5px;
    padding: 10px;
  }
}

.radioItem:checked + label {
  background-color: $accent-color;
  color: white;
}

.customBtn, .customBtn:hover {
  background-color: $accent-color;
  color: white;
}

.customBtn2, .customBtn2:hover {
  background-color: rgba(255, 0, 0, 0.678);
  color: white;
}

.modal-footer {
  justify-content: space-between;
}

.login-container {
  overscroll-behavior: none;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding-top: 50%;
}

.password-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}